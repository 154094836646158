/* --------------------------------------------
  breadcrumb_pc
----------------------------------------------- */

.mod-breadcrumb {
  width: 90%;
  max-width: $breakpoint-pc;
  margin: 0 auto;
  padding: 24px 0 30px;

  &-inner {
    display: flex;
    justify-content: flex-end;

    li {
      display: flex;
      font-weight: 500;

      &:not(:last-child)::after{
        content: ">";
        margin: 0 16px;
      }

      a {
        font-weight: 500;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
