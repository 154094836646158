/* --------------------------------------------
  sns_sp
----------------------------------------------- */

.sns {
  margin: 0 0 80px;
  padding: 80px 0;
  width: 100%;
  background-color: #F7F7E6;

  &__inner {
    width: 92%;
    margin: 0 auto;
  }
}