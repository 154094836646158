/* --------------------------------------------
  btn-tab_sp
----------------------------------------------- */

.mod-btn-tab {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;

  &__wrap {
    width: 100%;
  }

  &__wrap:last-child {
    .mod-btn-tab__btn {
      margin-bottom: 0;
    }
  }

  &__btn {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 19px;
    padding: 9px 29px;
    background-color: $goldColor;
    border-radius: 100px;
    border: 3px solid $goldColor;
    color: $btnTextColor;
    line-height: 1;
    text-align: left;
    font-size: 1.6rem;

    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 7px;
      height: 7px;
      border-top: 2px solid $btnTextColor;
      border-right: 2px solid $btnTextColor;
      transform: rotate(45deg);
    }

    &.is-active {
      background-color: $whiteColor;
    }
  }

}