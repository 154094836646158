/* --------------------------------------------
  dogo_sp
----------------------------------------------- */

#dogo {
  background:#F7F7E6;
  .top-heading {
    background-image: url("/assets/sweets/img/dogo/bac-dot01.jpg");
    background-repeat:repeat;
    background-size: 1366px auto;
    background-position: center center;
    height: 194px;
    width: 100%;
    margin-bottom: 400px;

    &__title {
      max-width: 247px;
      width: 65%;
      height: auto;
      margin: 0 auto;

      img {
        margin-top: 64px;
      }
    }
  }

  .top-intro {
    display: flex;
    width: 100%;
    align-items: stretch;
    margin-top: -122px;
    position: relative;

    &__txt {
      width: 75%;
      max-width: 281px;
      margin: 32px auto 0;

      &::after {
        display: block;
        width: 72px;
        height: 40px;
        background-image: url("/assets/sweets/img/dogo/ico-ribbon.svg");
        background-size: 100% auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -32px;
        margin: auto;
        content :'';
      }
    }

    &__bg {
      height: auto;
      background-image: url("/assets/sweets/img/dogo/bac-dot02.png");
      background-size: auto 100%;
      background-repeat: repeat-x;
      flex: 1;
      height: 107px;

      &--left {
        background-position: right bottom;
        margin-right: calc(65% + 20px);
      }
      &--right {
        background-position: left bottom;
      }
    }
  }

  .contents {

    &__title {
      padding-bottom: 22px;
      margin-bottom: 24px;
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 1.45;
      text-align: center;
      letter-spacing: .04em;
      border-bottom: 4px solid $goldColor;
    }

    .shop-list {
      padding: 90px 0 78px;
      width: 92%;
      margin: 0 auto;
      list-style: none;
      &::before,
      &::after {
        width: 222px;
        height: 12px;
        background-image: url("/assets/sweets/img/dogo/ico-line.png");
        background-size: 100% auto;
        display: block;
        content: '';
      }
      &::before {
        margin:0 auto 96px;
      }
      &::after {
        margin:10px auto 0;
      }

      a {
        color:#000000;
      }
  
      &__title {
        padding-bottom: 16px;
        margin-bottom: 48px;
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 1.45;
        text-align: center;
        color: #000000;
      }

      &__list {
        display: flex;
        flex-wrap:wrap;
      }

      &__item {
        width: 54%;
        max-width: 200px;
        margin: 0 auto 63px;
      }

      &__cake-name {
        font-size: 1.6rem;
        font-weight: bold;
      }

      .shop-thum {
        position: relative;
        margin-bottom: 16px;

        &__icon {
          position: absolute;
          left: 0;
          top: -37px;
          width: 71px;
          height: auto;
        }
      }
    }

    .dogo-about {
      background-image: url("/assets/sweets/img/dogo/bac-dogo-onsen.jpg");
      background-size: cover;
      display: block;
      width: 100%;
      height: auto;
      padding: 0;
      padding: 80px 0 80px;
      margin: 0 auto;
      color:#fff;

      &__title {
        text-align: center;
        font-weight:bold;
        font-size: 2.4rem;
        width: 92%;
        margin: 0 auto;
      }

      &__txt {
        text-align: center;
        font-size: 1.6rem;
        line-height: 1.75;
        width: 92%;
        margin: 38px auto 0;
        p {
          margin-bottom: 40px;
        }

        &::after {
          width: 72px;
          height: 40px;
          background-image: url("/assets/sweets/img/dogo/ico-ribbon-white.png");
          background-size: 100% auto;
          display: block;
          margin: 0 auto;
          content: '';
        }
      }
    }
  }

  .project {
    margin-bottom: 80px;

    &__lead {
      font-size: 1.6rem;
      margin-bottom: 40px;
      font-weight: 400;
      line-height: 1.75;
      letter-spacing: -.03em;
    }

    &__image {
      width: 100%;
      margin: 0 auto 19px;
    }

    &__detail{
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2;
      letter-spacing: -.03em;
    }
  }

  .dogo-facebook {
    width: 92%;
    margin: auto;
    max-width: 495px;
    padding: 80px 0;
  
    &__contents {
      width: 100%;
      height: auto;
    }
  }
  
  .contact {
    background:#151515;
    padding: 80px 0;
    color:#fff;
  }

  .footer {
    color:#fff;
    &__lower {
      background:#151515;
    }

    &__inner {
      padding: 0 0 80px;
    }
    &__backToTop {
      top: -80px;
    }
  }

}