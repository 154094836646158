/* --------------------------------------------
  dogo-detail_pc
----------------------------------------------- */

#dogo-detail {
  background:#F7F7E6;
  color:#000000;

  .contents {
    a {
      color:#000000;
    }
  }

  .top-heading {
    background-repeat:no-repeat;
    background-size: cover !important;
    background-position: center center  !important;
    height: 380px;
    width: 100%;
    margin-bottom: 40px;
    position: relative;

    &__breadcrumb {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      padding: 24px 0;
      li {
        color:#fff;
        &:not(:last-child)::after {
          color:#fff;
        }
      }
    }
  }

  // 店舗の基本情報
  .shop-head {
    width: 88%;
    max-width: 1080px;
    margin: 0 auto;
    position :relative;
    padding-left: 130px;
    padding-bottom: 8px;
    margin-bottom: 48px;

    &::before {
      display: block;
      width: 105px;
      height: 111px;
      background-size: 100% auto !important;
      content :'';
      position: absolute;
      left: 0;
      top: 0;
      margin: auto;
    }

    &--01 {
      border-bottom: 4px solid #EF858C;

      &::before {
        background: url("/assets/sweets/img/dogo/ico-dogo-roll.png");
      }
      .shop-info__title {
        background:#EFD7CE;
      }
    }
    &--02 {
      border-bottom: 4px solid #dae24a;

      &::before {
        background: url("/assets/sweets/img/dogo/ico-dogo-roll-02.png");
      }
      .shop-info__title {
        background:#e2e68c;
      }
    }
    &--03 {
      border-bottom: 4px solid #fbbd00;

      &::before {
        background: url("/assets/sweets/img/dogo/ico-dogo-roll-03.png");
      }
      .shop-info__title {
        background:#f4dd96;
      }
    }
    &--04 {
      border-bottom: 4px solid #d8a502;

      &::before {
        background: url("/assets/sweets/img/dogo/ico-dogo-roll-04.png");
      }
      .shop-info__title {
        background:#e2d4ac;
      }
    }
    &--05 {
      border-bottom: 4px solid #b0a64f;

      &::before {
        background: url("/assets/sweets/img/dogo/ico-dogo-roll-05.png");
      }
      .shop-info__title {
        background:#e3dc9c;
      }
    }
    &--06 {
      border-bottom: 4px solid #8c4700;

      &::before {
        background: url("/assets/sweets/img/dogo/ico-dogo-roll-06.png");
      }
      .shop-info__title {
        background:#ddc6af;
      }
    }
    &--07 {
      border-bottom: 4px solid #ea5452;

      &::before {
        background: url("/assets/sweets/img/dogo/ico-dogo-roll-07.png");
      }
      .shop-info__title {
        background:#f0cfce;
      }
    }
    &--08 {
      border-bottom: 4px solid #9a6666;

      &::before {
        background: url("/assets/sweets/img/dogo/ico-dogo-roll-08.png");
      }
      .shop-info__title {
        background:#cbafaf;
      }
    }
    &--09 {
      border-bottom: 4px solid #6fa24b;

      &::before {
        background: url("/assets/sweets/img/dogo/ico-dogo-roll-09.png");
      }
      .shop-info__title {
        background:#bed9ab;
      }
    }
    &--10 {
      border-bottom: 4px solid #c0b7db;

      &::before {
        background: url("/assets/sweets/img/dogo/ico-dogo-roll-10.png");
      }
      .shop-info__title {
        background:#c7c3d5;
      }
    }

    &__lead {
      font-size: 1.6rem;
      font-weight:bold;
      margin-bottom: 6px;
      line-height: 1.5;
    }

    &__title {
      font-size: 3.2rem;
      font-weight:bold;
      margin-bottom: 10px;
      line-height: 1.46;
    }

    &__info {
      display: flex;
      flex-wrap: wrap;
    }

    .shop-info {
      width: 49%;
      margin-right: 1%;
      display: flex;
      margin-bottom: 16px;
      align-items: center;

      &__title {
        // background:#EFD7CE;
        height: 26px;
        width: 95px;
        line-height: 26px;
        font-weight:bold;
        margin-right: 22px;
        text-align: center;

        .only-pc {
          display: inline;
        }
      }

      &__txt {
        flex: 1;
      }
    }
  }

  // 店舗説明
  .shop-content {
    width: 88%;
    max-width: 1048px;
    margin: 0 auto 112px;

    &__title {
      font-size: 2.4rem;
      font-weight:bold;
      text-align: center;
      margin-bottom: 38px;
    }

    .shop-description {
      margin-bottom: 46px;
      &__txt {
        margin-bottom: 28px;
        font-size: 1.6rem;
        line-height: 1.75;
      }
    }

    .shop-image {
      max-width: 808px;
      width: 78%;
      margin: 0 auto 48px;
      display: flex;
      justify-content: space-between;

      &--center {
        justify-content: center;
      }
      &__item {
        width: 47%;
        max-width: 380px;
      }
    }

    .shop-fate {
      border-radius: 10px;
      border: 4px solid #BD4C44;
      box-sizing: border-box;
      padding: 34px 32px 48px;
      position :relative;

      &__title {
        font-size: 2.4rem;
        font-weight:bold;
        text-align: center;
        padding-bottom :18px;
        span {
          color:#BD4C44;
        }
      }

      &__txt {
        font-size: 1.6rem;
        text-align: left;
        line-height: 1.75;
      }

      &::after {
        display: block;
        width: 72px;
        height: 41px;
        background: url("/assets/sweets/img/dogo/ico-ribbon-red.svg");
        background-size: 100% auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20px;
        margin: auto;
        content: '';
      }
    }
  }

  // 店舗情報
  .shop-outline {
    width: 88%;
    max-width: 1048px;
    margin: 0 auto;
    position: relative;

    &__title {
      font-size: 2.4rem;
      font-weight:bold;
      text-align: center;
      margin-bottom: 20px;
    }

    &__map {
      margin-left: 132px;
      width: calc( 100% - 132px);
      height: 350px;
    }

    .shop-table {
      width: 100%;
      font-size: 1.6rem;

      .only-pc {
        display: inline;
      }

      &__row {
        border-bottom: 1px solid #E2E2B8;

        &:last-of-type {
          border-bottom: none;
        }
      }
      &__head {
        width: 132px;
        padding: 20px 0;
        position :relative;
        vertical-align: middle;

        &::before {
          display: block;
          width: 18px;
          height: 18px;
          background: url("/assets/sweets/img/dogo/ico-yudama.png");
          background-size: 100% auto;
          content :'';
          position: absolute;
          left: 0;
          bottom: 0;
          top: 0;
          margin: auto;
        }
      }

      &__data {
        padding: 20px 0;

        a {
          display: inline-block;
        }
      }
    }

    &::after {
      width: 222px;
      height: 12px;
      background-image: url("/assets/sweets/img/dogo/ico-line.png");
      background-size: 100% auto;
      display: block;
      content: '';
      margin:120px auto 90px;
    }


  }

  // その他の店舗
  .other-shop {
    list-style: none;
    padding: 0 0 72px;
    width: 88%;
    max-width: 996px;
    margin: 0 auto;

    &__title {
      margin-bottom: 38px;
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 1.45;
      text-align: center;
    }
    &__list {
      display: flex;
      flex-wrap:wrap;
      justify-content: space-between;
    }

    &__thum {
      position: relative;
      margin-bottom: 16px;
    }

    &__icon {
      position: absolute;
      left: 0;
      top: -37px;
      width: 71px;
      height: auto;
    }

    &__item {
      max-width: 180px;
      text-align: left;
    }

    &__cake-name {
      font-size: 1.6rem;
      font-weight: bold;
    }

    .slick-slide {
      text-align: center;
    }
    .slick-list {
      padding-top: 40px;
    }
    .slick-prev,
    .slick-next {
      width: 14px;
      height: 25px;
      display: block;
      content: '';
      position: absolute;
      font-size: 0;
      top: 120px;
    }

    .slick-prev {
      background-image: url("/assets/sweets/img/dogo/ico-arrow-left.svg");
      left: -42px;
      margin: auto;
    }
    .slick-next {
      background-image: url("/assets/sweets/img/dogo/ico-arrow-right.svg");
      right: -42px;
      margin: auto;
    }
  }

  // お問い合わせ
  .contact {
    background:#151515;
    padding: 80px 0;
    color:#fff;
  }

  .contents {
  }

  .footer {
    color:#fff;
    &__inner {
      padding: 0 0 80px;
    }
    &__lower {
      background:#151515;
    }
    &__backToTop {
      top: -80px;
    }
  }

  @media only screen and (min-width: $breakpoint) and (max-width: 1200px){
    .other-shop {
      &__item {
        padding: 0 5px;
      }

      .slick-prev,
      .slick-next {
        top: 100px;
      }
      .slick-prev {
        left: -32px;
      }
      .slick-next {
        right: -32px;
      }
    }
  }

}