/* --------------------------------------------
  news_sp
----------------------------------------------- */

#news {
  .mod-heading {
    background-image: url("/assets/sweets/img/news/bac-news_sp.jpg");
  }

  .tab {
    width: 90%;
    padding-top: 48px;
    margin: 0 auto;
    max-width: 248px;
  }

  .news {
    padding: 58px 0 120px;

    &__inner {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: 0 auto 120px;
      max-width: $breakpoint-pc;
    }

  }

  .page-btn {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 92%;

    & > a {
      width: 45%;
      max-width: 157px;
      margin-bottom: 40px;

      &:not(:first-child) {
        margin-left: 31px;
      }
    }
  }

  .page-top-btn {
    width: 240px;
    margin: 0 auto;
  }

}
