/* --------------------------------------------
  concept_sp
----------------------------------------------- */

#concept {
  .mod-heading {
    background-image: url("/assets/sweets/img/concept/bac-concept_sp.jpg");
  }

  .contents {
    padding: 48px 0 78px;
    width: 92%;
    margin: 0 auto;

    &__title {
      padding-bottom: 22px;
      margin-bottom: 24px;
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 1.45;
      text-align: center;
      letter-spacing: .04em;
      border-bottom: 4px solid $goldColor;
    }
  }

  .project {
    margin-bottom: 80px;

    &__lead {
      font-size: 1.6rem;
      margin-bottom: 40px;
      font-weight: 400;
      line-height: 1.75;
      letter-spacing: -.03em;
    }

    &__image {
      width: 100%;
      margin: 0 auto 19px;
    }

    &__detail{
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2;
      letter-spacing: -.03em;
    }
  }

  .island {
    &__image {
      max-width: 236px;
      width: 68%;
      margin: 0 auto 44px;
      padding-top: 15px;
    }

    &__image-caption {
      margin-bottom: 22px;
      text-align: center;
      font-size: 1.8rem;
      font-weight: 500;
    }

    &__text {
      margin-bottom: 44px;
      font-size: 1.6rem;
      line-height: 1.8;
      font-weight: 400;
    }


    .list {
      width: 100%;

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 16px 24px;
        margin: 0 auto 14px;
        background-color: $whiteColor;
        border: 1px solid #633E28;
        border-radius: 10px;

        &:last-child {
          margin: 0 auto;
        }
      }

      &__number {
        width: 48px;
        height: 48px;
        margin-bottom: 16px;
        border-radius: 100px;

        span {
          color: #633E28;
        }
      }

      &__detail {
        flex: 1;
        color: #633E28;
        font-size: 1.6rem;
        line-height: 1.5;
      }


    }

  }

}