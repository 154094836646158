/* --------------------------------------------
  header_sp
----------------------------------------------- */

#top {
  .news {
    padding: 80px 0 80px;

    &__title {
      width: 35%;
      max-width: 124px;
      margin: 0 auto;
    }

    &__inner {
      width: 92%;
      margin: 0 auto;
    }

    &__contents {
      margin-bottom: 48px;
    }

    .news-btn {
      width: 240px;
      margin: 0 auto;
    }
  }
}