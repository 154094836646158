/* --------------------------------------------
  heading_pc
----------------------------------------------- */

// background-imageを指定する想定です

.mod-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  background-size: cover;
  background-position: bottom left;
  background-repeat: no-repeat;

  &__title {
    font-size: 3.2rem;
    font-weight: 500;
  }

}