/* --------------------------------------------
  footer_sp
----------------------------------------------- */

.footer {
  &__upper {
    background-color: #F7F7E6;
  }

  &__inner {
    position: relative;
    padding: 80px 0;
    width: 92%;
    margin: 0 auto;
  }

  &__backToTop {
    position: absolute;
    transform: translateY(-50%);
    top: 0;
    right: -8px;
    width: 80px;
    height: 80px;
    text-indent: -9999px;
    background-image: url("/assets/sweets/img/common/ico-pagetop.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &__image {
    width: 81%;
    margin: 0 auto 31px;
  }

  &__text {
    color: #633E28;
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.67;
  }

  &__lower {
    padding: 80px 0 90px;
    text-align: center;
    small {
      font-size: 1.6rem;
      font-weight: 300;
    }
  }
}