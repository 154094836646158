/* --------------------------------------------
  dogo-detail_sp
----------------------------------------------- */

#dogo-detail {
  background:#F7F7E6;
  color:#000000;
  a {
    color:#000000;
  }

  .top-heading {
    background-repeat:no-repeat !important;
    background-size: cover !important;
    background-position: right center !important;
    height: 222px;
    width: 100%;
    position:relative;

    &::after {
      display: block;
      width: 122px;
      height: 128px;
      background: url("/assets/sweets/img/dogo/ico-dogo-roll.png");
      background-size: 100% auto;
      content :'';
      position: absolute;
      left: 4%;
      bottom: -64px;
      margin: auto;
    }
  }

  // 店舗の基本情報
  .shop-head {
    width: 92%;
    margin: 0 auto 48px;
    position :relative;
    margin-top: 80px;
    padding-bottom: 8px;
    border-bottom: 4px solid #EF858C;
    color:#000000;

    &__lead {
      font-size: 1.4rem;
      font-weight:bold;
      margin-bottom: 8px;
      line-height: 1.42;
    }

    &__title {
      font-size: 3.2rem;
      font-weight:bold;
      margin-bottom: 16px;
      line-height: 1.46;
      letter-spacing: -0.02em;
    }

    &__info {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }

    .shop-info {
      width: 100%;
      display: flex;
      margin-bottom: 16px;
      align-items: center;
      &__title {
        background:#EFD7CE;
        height: auto;
        width: 55px;
        min-height: 26px;
        font-weight:bold;
        margin-right: 16px;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.4;
        padding: 3px 0;
      }

      &__txt {
        flex: 1;
        font-weight:bold;
      }
    }
  }

  // 店舗説明
  .shop-content {
    width: 88%;
    max-width: 1080px;
    margin: 0 auto 100px;

    &__title {
      font-size: 2.4rem;
      font-weight:bold;
      text-align: center;
      margin-bottom: 28px;
    }

    .shop-description {
      margin-bottom: 42px;

      &__txt {
        margin-bottom: 32px;
        font-size: 1.6rem;
        line-height: 1.75;
      }
    }

    .shop-image {
      width: 100%;
      margin: 0 0 48px 0;
      &__item {
        margin: 0 0 32px 0;
      }
    }

    .shop-fate {
      border-radius: 10px;
      border: 4px solid #BD4C44;
      box-sizing: border-box;
      padding: 40px 32px 52px 32px;
      position :relative;

      &__title {
        font-size: 2.4rem;
        font-weight:bold;
        text-align: center;
        padding-bottom :16px;
        span {
          color:#BD4C44;
        }
      }

      &__txt {
        font-size: 1.6rem;
        text-align: left;
        line-height: 1.75;
      }

      &::after {
        display: block;
        width: 72px;
        height: 41px;
        background: url("/assets/sweets/img/dogo/ico-ribbon-red.svg");
        background-size: 100% auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20px;
        margin: auto;

        content: '';
      }
    }
  }

  // 店舗情報
  .shop-outline {
    width: 88%;
    max-width: 1048px;
    margin: 0 auto;
    position: relative;

    &__title {
      font-size: 2.4rem;
      font-weight:bold;
      text-align: center;
      margin-bottom: 14px;
    }

    &__map {
      margin-left: 0;
      width: 100%;
      height: 350px;
    }

    .shop-table {
      width: 100%;
      font-size: 1.6rem;

      &__row {
        border-bottom: 1px solid #E2E2B8;

        &:last-of-type {
          border-bottom: none;
        }
      }
      &__head {
        width: 130px;
        padding: 20px 0;
        position :relative;
        vertical-align: middle;

        &::before {
          display: block;
          width: 18px;
          height: 18px;
          background: url("/assets/sweets/img/dogo/ico-yudama.png");
          background-size: 100% auto;
          content :'';
          position: absolute;
          left: 0;
          bottom: 0;
          top: 0;
          margin: auto;
        }
      }

      &__data {
        padding: 20px 0;
        a {
          display: inline-block;
        }
      }
    }

    &::after {
      width: 222px;
      height: 12px;
      background-image: url("/assets/sweets/img/dogo/ico-line.png");
      background-size: 100% auto;
      display: block;
      content: '';
      margin: 95px auto 95px;
    }
  }

  // その他の店舗
  .other-shop {
    list-style: none;
    padding: 0 0 0;
    width: 88%;
    max-width: 996px;
    margin: 0 auto 80px;

    &__title {
      margin-bottom: 38px;
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 1.45;
      text-align: center;
    }
    &__list {
      display: flex;
      flex-wrap:wrap;
      justify-content: space-between;
    }

    &__thum {
      position: relative;
      margin-bottom: 16px;
    }

    &__icon {
      position: absolute;
      left: 0;
      top: -37px;
      width: 71px;
      height: auto;
    }

    &__item {
      max-width: 180px;
      text-align: left;
    }

    &__cake-name {
      font-size: 1.6rem;
      font-weight: bold;
    }

    .slick-slide {
      text-align: center;
    }
    .slick-list {
      padding-top: 40px;
    }
    .slick-prev,
    .slick-next {
      width: 14px;
      height: 25px;
      display: block;
      content: '';
      position: absolute;
      font-size: 0;
      top: 120px;
    }

    .slick-prev {
      background-image: url("/assets/sweets/img/dogo/ico-arrow-left.svg");
      left: 0;
      margin: auto;
    }
    .slick-next {
      background-image: url("/assets/sweets/img/dogo/ico-arrow-right.svg");
      right: 0;
      margin: auto;
    }
  }
  
  .contact {
    background:#151515;
    padding: 80px 0;
    color:#fff;
  }

  .footer {
    color:#fff;
    &__lower {
      background:#151515;
    }
    &__inner {
      padding: 0 0 80px;
    }
    &__backToTop {
      top: -80px;
    }
  }
}