/* --------------------------------------------
   mod-form_pc
----------------------------------------------- */

.mod-form {

  // インプットテキスト
  &__input-txt {
    width: 100%;
    background:#fff;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    border: 1px solid #D9D9D9;
  }


  // セレクトボックス
  &__selectbox {
    position: relative;
  }

  &__select {
    width: 100%;
    background: #fff;
    height: 40px;
    border: 1px solid #D9D9D9;
    position: relative;
    padding: 0 10px;
    z-index: 1;
  }

  &__select-label {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate(0, -50%) rotate(90deg);
    width: 9px;
    height: 16px;
    background-image: url(/assets/img/common/ico-arrow-gray.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 2;
  }

  // テキストエリア
  &__textarea {
    width: 100%;
    background:#fff;
    border: 1px solid #D9D9D9;
    padding: 10px;
    box-sizing: border-box;
  	resize: vertical;
  }

  // チェックボックス
  &__checkbox {
    display: none;

    &:checked {
      &+label {
        .ico {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  // チェックボックスのラベル
  &__check-label {
    position: relative;
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    color: #333333;

    .ico {
      position: absolute;
      top: calc(1.6em / 2);
      left: 0;
      transform: translate(0, -50%);
      display: block;
      width: 14px;
      height: 14px;
      border: solid 1px #D9D9D9;
      border-radius: 2px;
      background-color: $whiteColor;

      &::before {
        content: '';
        position: absolute;
        top: 42%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(315deg);
        display: block;
        width: 6px;
        height: 3px;
        border-bottom: solid 1px #3B4043;
        border-left: solid 1px #3B4043;
        opacity: 0;
      }
    }
  }
}
