/* --------------------------------------------
  news_pc
----------------------------------------------- */

#news {
  .mod-heading {
    background-image: url("/assets/sweets/img/news/bac-news_pc.jpg");
  }

  .tab {
    width: 90%;
    margin: 0 auto;
    max-width: 910px;
  }

  .news {
    padding: 65px 0 120px;

    &__inner {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: 0 auto 120px;
      max-width: $breakpoint-pc;
    }

  }

  .page-btn {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 536px;
    & > a {
      width: 240px;
      margin-bottom: 40px;
      &:not(:first-child) {
        margin-left: 50px;
      }
    }
  }

  .page-top-btn {
    width: 240px;
    margin: 0 auto;
  }

}


@media only screen and (max-width: $breakpoint-pc) {
  #top {
    .news {
      &__title {
        width: 15%;
      }

    }
  }
}