/* --------------------------------------------
  concept_pc
----------------------------------------------- */

#concept {
  .mod-heading {
    background-image: url("/assets/sweets/img/concept/bac-concept_pc.jpg");
  }

  .contents {
    padding: 61px 0 119px;
    width: 88%;
    max-width: 1048px;
    margin: 0 auto;

    &__title {
      padding-bottom: 16px;
      margin-bottom: 48px;
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 1.45;
      text-align: center;
      border-bottom: 4px solid $goldColor;
    }
  }

  .project {
    margin-bottom: 121px;

    &__lead {
      font-size: 1.6rem;
      margin-bottom: 34px;
      font-weight: 400;
    }

    &__image {
      width: 70%;
      max-width: 732px;
      margin: 0 auto 24px;
    }

    &__detail{
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.42;

      dt {
        margin-bottom: 2px;
      }
    }
  }

  .island {
    &__image {
      width: 236px;
      margin: 0 auto 29px;
    }

    &__image-caption {
      margin-bottom: 25px;
      text-align: center;
      font-size: 1.8rem;
      font-weight: 500;
    }

    &__text {
      margin-bottom: 44px;
      font-size: 1.6rem;
      line-height: 2;
      font-weight: 400;
    }


    .list {
      width: 100%;

      &__item {
        display: flex;
        align-items: center;
        padding: 30px 40px;
        margin: 0 auto 8px;
        background-color: $whiteColor;
        border: 1px solid #633E28;
        border-radius: 10px;

        &:last-child {
          margin: 0 auto;
        }
      }

      &__number {
        width: 48px;
        height: 48px;
        margin-right: 16px;
        border-radius: 100px;

        span {
          color: #633E28;
        }
      }

      &__detail {
        flex: 1;
        color: #633E28;
        font-size: 1.6rem;
      }


    }

  }

}