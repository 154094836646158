/* --------------------------------------------
  contact_pc
----------------------------------------------- */

#contact {
  .mod-heading {
    background-image: url("/assets/sweets/img/contact/bac-contact_pc.jpg");
  }

  .contents {
    padding: 0 0 120px;

    &__inner {
      margin: 64px auto 0;
      max-width: $breakpoint-pc;
      width: 92%;
    }

    &__title {
      padding-bottom: 16px;
      margin-bottom: 48px;
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 1.45;
      text-align: center;
      border-bottom: 4px solid $goldColor;
    }

    &__attention {
      margin-bottom: 64px;
      font-size: 1.6rem;
    }
  }


  // お問い合わせフォーム
  .contact {
    letter-spacing: .05em;

    &__wrap {
      margin: 40px 0 48px;
      background: #F4F4F4;
    }

    &__inner {
      max-width: 960px;
      width: calc(100% - 24px * 2);
      margin: 0 auto;
      padding: 56px 0;
    }

    // 問い合わせ項目
    &-item {
      display: flex;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      &__ttl {
        width: 196px;
        margin-top: 5px;
        color: #33393C;
        font-weight: bold;
        font-size: 1.6rem;
      }

      &__required {
        color:#E20000;
        font-size: 1.4rem;
        margin-left: 8px;
      }

      &__data {
        width: 100%;
        flex: 1;

        &.is-error {
          .mod-form__input-txt {
            border: 1px solid #E20000;
          }

          .mod-form__textarea {
            border: 1px solid #E20000;
          }
        }
      }

      &__selectbox {
        width: 184px;
        margin-bottom: 12px;
      }

      &__txt {
        font-size: 1.6rem;
        margin-left :10px;
        color: #33393C;
      }

      &__error {
        color: #E20000;
        font-size: 1.3rem;

        &:first-of-type {
          margin-top: 6px;
        }
      }
    }

    // ボタン
    &-btn {
      margin: 0 8px;
      width: 240px;

      &__wrap {
        display: flex;
        justify-content: center;
      }
    }

    // 送信完了画面
    &-end {
      &__lead {
        margin: 40px 0 29px;
        font-size: 1.6rem;
        line-height: 2em;
        text-align: center;
      }

      &__wrap {
        padding: 18px 0;
        margin: 0 0 48px;
        border: 4px solid #D5D5D5;
        background-color: $whiteColor;
        text-align :center;
      }

      &__tel {
        margin-bottom : 16px;
        font-size: 2.4rem;
        font-weight:bold;
        color: #33393C;

        .only-pc {
          display: inline;
        }
      }

      &__address {
        font-size: 1.6rem;
        color: #33393C;
      }
    }
  }

  // 個人情報取扱同意書
  .agreement {
    margin: 0 auto 39px;
    width: 620px;
    height: 260px;
    border: 1px solid #D9D9D9;
    background-color: $whiteColor;

    &__inner {
      margin: 27px auto 0;
      padding-right: 8px;
      width: 90%;
      height: 205px;
      overflow-y: auto;
    }

    &__ttl {
      margin-bottom: 20px;
      font-weight: bold;
      color: #33393C;
      text-align :center;
    }

    &__txt {
      color:#7E7E7E;
      font-size: 12px;

      a {
        color: #333333;
      }
    }
  }

  // 同意する
  .agree {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-bottom: 50px;

    &__txt {
      margin-left: 30px;
      font-size: 1.4rem;
      font-weight: normal;
      color: $whiteColor;
    }
  }

}