/* --------------------------------------------
  pdf-list_sp
----------------------------------------------- */

.mod-pdf-list {
  width: 100%;

  .pdf-item {
    position: relative;
    padding: 31px 10px 31px 15px;
    border-bottom: 1px solid $goldColor;

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__image {
      width: 122px;
    }

    &__detail {
      flex: 1;
      padding-left: 16px;
    }

    &::before {
      position: absolute;
      content: "";
      vertical-align: middle;
      right: 18px;
      top: 50%;
      width: 5px;
      height: 5px;
      border-top: 2px solid $goldColor;
      border-right: 2px solid $goldColor;
      transform: rotate(45deg) translateY(-50%);
    }
  }

  .item-detail {

    &__title {
      margin-bottom: 8px;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.43;
    }

    &__text {
      font-size: 1.4rem;
      line-height: 1.42;
      font-weight: 300;
    }

  }

}

.mod-pdf-list.js-mod-pdf-list.is-hide {
  opacity: 0;
}