/* --------------------------------------------
  sns_pc
----------------------------------------------- */

.sns {
  margin: 0 0 80px;
  padding: 80px 0;
  width: 100%;
  background-color: #F7F7E6;
  &__inner {
    display: flex;
    justify-content: space-between;
    max-width: $breakpoint-pc;
    width: 90%;
    margin: 0 auto;
  }
}