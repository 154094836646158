/* --------------------------------------------
  news-list_sp
----------------------------------------------- */

.mod-news-list {
  width: 100%;

  .news-article {
    position: relative;
    padding: 31px 10px 31px 0;
    border-bottom: 1px solid $goldColor;

    &__inner {
      display: flex;
      justify-content: space-between;
    }

    &__image {
      width: 110px;
    }

    &__detail {
      flex: 1;
      padding-left: 16px;
    }

    &::before {
      position: absolute;
      content: "";
      vertical-align: middle;
      right: 5px;
      top: 50%;
      width: 5px;
      height: 5px;
      border-top: 1px solid $goldColor;
      border-right: 1px solid $goldColor;
      transform: rotate(45deg) translateY(-50%);
    }
  }

  .article-detail {
    &__upper {
      width: 100%;
      margin-bottom: 9px;
    }

    &__day {
      font-size: 1.4rem;
      margin-bottom: 5px;
    }

    &__tag {
      display: inline-block;
      padding: 2px 14px;
      background-color: $goldColor;
      color: $btnTextColor;
      font-size: 1.2rem;
      line-height: 1.5;
      border-radius: 100px;
    }

    &__title {
      margin-bottom: 6px;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.5;
    }

    &__text {
      font-size: 1.4rem;
      line-height: 1.7;
      font-weight: 300;
    }

  }

}

.mod-news-list.js-mod-news-list.is-hide {
  opacity: 0;
}