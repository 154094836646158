/* --------------------------------------------
  facebook_sp
----------------------------------------------- */

.facebook {
  width: 100%;
  margin: auto;
  max-width: 495px;

  &__contents {
    width: 100%;
    height: auto;
  }
}
