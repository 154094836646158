/* --------------------------------------------
  news-list_pc
----------------------------------------------- */

.mod-news-list {
    width: 100%;

    .news-article {
      position: relative;
      padding: 32px 30px 32px 14px;
      border-bottom: 1px solid $goldColor;

      &__inner {
        display: flex;
        justify-content: space-between;
      }

      &__image {
        width: 122px;
      }

      &__detail {
        flex: 1;
        padding-left: 34px;
      }

      &::before {
        position: absolute;
        content: "";
        vertical-align: middle;
        right: 22px;
        top: 50%;
        width: 5px;
        height: 5px;
        border-top: 2px solid $goldColor;
        border-right: 2px solid $goldColor;
        transform: rotate(45deg) translateY(-50%);
      }
    }

    .article-detail {
      &__upper {
        display: flex;
        width: 100%;
        margin-bottom: 16px;
      }

      &__day {
        font-size: 1.2rem;
        margin-right: 16px;
        font-weight: 300;
      }

      &__tag {
        padding: 4px 14px;
        background-color: $goldColor;
        color: $btnTextColor;
        font-size: 1.0rem;
        line-height: 1.5;
        border-radius: 100px;
      }

      &__title {
        margin-bottom: 12px;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.5;
      }

      &__text {
        font-size: 1.4rem;
        line-height: 1.7;
        font-weight: 300;
      }

    }

}




@media only screen and (max-width: $breakpoint-pc) {
  .mod-news-list{

    .article-detail {

      &__title {
        font-size: 1.6rem;
      }

      &__text {
        font-size: 1.4rem;
      }

    }

  }
}

.mod-news-list.js-mod-news-list.is-hide {
  opacity: 0;
}