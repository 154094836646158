/* --------------------------------------------
  contest_sp
----------------------------------------------- */

#contest {
  .mod-heading {
    background-image: url("/assets/sweets/img/contest/bac-contest_sp.jpg");
  }

  .contents {
    padding: 17px 0 120px;

    &__inner {
      margin: 0 auto 83px;
      width: 100%;
    }

    .top-btn {
      width: 240px;
      margin: 0 auto;
    }
  }

}