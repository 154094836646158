/* --------------------------------------------
  dogo_pc
----------------------------------------------- */

#dogo {
  background:#F7F7E6;
  color:#000000;
  .footer {
    color:#fff;

    &__inner {
      padding: 0 0 80px;
    }
    &__lower {
      background:#151515;
    }
    &__backToTop {
      top: -80px;
    }
  }

  .top-heading {
    background: url("/assets/sweets/img/dogo/bac-dot01.jpg"), #F7F7E6;
    background-repeat:repeat;
    background-size: 1366px auto, auto;
    background-position: center center, center;
    height: 232px;
    width: 100%;
    margin-bottom: 300px;

    &__title {
      width: 352px;
      height: auto;
      margin: 0 auto;

      img {
        margin-top: 48px;
      }
    }
  }

  .top-intro {
    display: flex;
    width: 100%;
    align-items: stretch;
    margin-top: -184px;
    position: relative;

    &::after {
      display: block;
      width: 72px;
      height: 40px;
      background-image: url("/assets/sweets/img/dogo/ico-ribbon.svg");
      background-size: 100% auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -5%;
      margin: auto;
      content :'';
    }

    &__txt {
      width: 166px;

      &--left {
        margin: 42px 412px 0 44px;
      }
      &--right {
        margin: 42px 44px 0 0;
      }
    }

    &__bg {
      height: auto;
      background-image: url("/assets/sweets/img/dogo/bac-dot02.png");
      background-size: auto 100%;
      flex: 1;
      background-repeat: repeat-x;

      &--left {
        background-position: right bottom;
      }
      &--right {
        background-position: left bottom;
      }
    }
  }

  .contents {
    background:#F7F7E6;
    a {
      color:#000000;
    }
  
    .shop-list {
      list-style: none;
      padding: 76px 0 96px;
      width: 88%;
      max-width: 1080px;
      margin: 0 auto;

      &::before,
      &::after {
        width: 222px;
        height: 12px;
        background-image: url("/assets/sweets/img/dogo/ico-line.png");
        background-size: 100% auto;
        display: block;
        content: '';
      }
      &::before {
        margin:0 auto 96px;
      }
      &::after {
        margin:0 auto 0;
      }

      &__title {
        padding-bottom: 16px;
        margin-bottom: 62px;
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 1.45;
        text-align: center;
        color: #000000;
      }
      &__list {
        display: flex;
        flex-wrap:wrap;
        justify-content: space-between;
      }

      &__item {
        width: 16.7%;
        max-width: 180px;
        margin-right: 4%;
        margin-bottom: 63px;

        &:nth-of-type(5),
        &:last-of-type {
          margin-right: 0;
        }
      }

      &__cake-name {
        font-size: 1.6rem;
        font-weight: bold;
      }

      .shop-thum {
        position: relative;
        margin-bottom: 16px;

        &__icon {
          position: absolute;
          left: 0;
          top: -37px;
          width: 71px;
          height: auto;
        }
      }
    }

    .dogo-about {
      background-image: url("/assets/sweets/img/dogo/bac-dogo-onsen.jpg");
      background-size: cover;
      display: block;
      width: 100%;
      height: auto;
      padding: 0;
      padding: 80px 0 80px;
      margin: 0 auto;
      color:#fff;

      &__title {
        text-align: center;
        font-weight:bold;
        font-size: 2.4rem;

        .only-pc {
          display: inline;
        }
      }

      &__txt {
        margin-top: 34px;
        text-align: center;
        font-size: 1.6rem;
        line-height: 1.75;
        width: 92%;
        margin: 34px auto 0;

        p {
          margin-bottom: 28px;
        }

        &::after {
          width: 72px;
          height: 40px;
          background-image: url("/assets/sweets/img/dogo/ico-ribbon-white.png");
          background-size: 100% auto;
          display: inline-block;
          margin: ​auto ;
          margin-top: 10px;
          content: '';
        }
      }
    }
  }

  .project {
    margin-bottom: 121px;

    &__lead {
      font-size: 1.6rem;
      margin-bottom: 34px;
      font-weight: 400;
    }

    &__image {
      width: 70%;
      max-width: 732px;
      margin: 0 auto 24px;
    }

    &__detail{
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.42;

      dt {
        margin-bottom: 2px;
      }
    }
  }

  .contact {
    background:#151515;
    padding: 80px 0;
    color:#fff;
  }

  .dogo-facebook {
    padding: 80px 0;
    .fb-page {
      margin: 0 auto;
      max-width: 500px;
      display: block;
    }
  }
}