/* --------------------------------------------
  campaign_pc
----------------------------------------------- */

#campaign {
  .mod-heading {
    background-image: url("/assets/sweets/img/campaign/bac-campaign_pc.jpg");
  }

  .contents {
    padding: 0 0 120px;

    &__inner {
      margin: 0 auto 124px;
      max-width: $breakpoint-pc;
      width: 92%;
    }

    .top-btn {
      width: 240px;
      margin: 0 auto;
    }
    
    .product {
      &__pagination {
        margin: 0 0 80px 0;
  
        .pagination {
          &__items {
            margin: -8px;
            font-size: 0;
            text-align: center;
          }
  
          &__item {
            display: inline-block;
            width: 40px;
            height: 40px;
            margin: 8px;
  
            a,
            span {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              width: 100%;
              height: 100%;
              font-size: 1.8rem;
              font-weight: 700;
            }
  
            a {
              color: #633E28;
              background: #FFFFFF;
              border: solid 1px #D3D27B;
            }
  
            span {
              color: #FFFFFF;
              background: #BABABA;
            }
  
            &--prev,
            &--next {
              a {
                position: relative;
                color: #D3D27B;
                background: #D3D27B;
  
                &::before,
                &::after {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  display: block;
                  width: 6px;
                  height: 10px;
                  background-image: url(/assets/img/common/ico-arrow-white.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                }
              }
            }
  
            &--prev {
              a {
                &::before {
                  transform: translate(-50%, -50%) rotate(180deg);
                }
  
                &::after {
                  display: none;
                }
              }
            }
  
            &--next {
              a {
                &::before {
                  display: none;
                }
  
                &::after {
                  transform: translate(-50%, -50%) rotate(0);
                }
              }
            }
          }
        }
      }
    }
  }

}