/* --------------------------------------------
  contact_sp
----------------------------------------------- */

#contact {
  .mod-heading {
    background-image: url("/assets/sweets/img/contact/bac-contact_sp.jpg");
  }

  .contents {
    width: 92%;
    margin: 0 auto;
    padding: 17px 0 106px;

    &__inner {
      margin: 49px auto 0;
      width: 100%;
    }

    &__title {
      padding-bottom: 22px;
      margin-bottom: 24px;
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 1.45;
      text-align: center;
      letter-spacing: .04em;
      border-bottom: 4px solid $goldColor;
    }

    &__attention {
      margin-bottom: 32px;
      font-size: 1.6rem;
    }

  }

  // お問い合わせフォーム
  .contact {
    letter-spacing: .05em;

    &__wrap {
      background: #F4F4F4;
      margin: 30px 0 42px;
    }

    &__inner {
      max-width: 800px;
      width: calc(100% - 24px * 2);
      margin: 0 auto;
      padding: 28px 0;
    }

    // 問い合わせ項目
    &-item {
      display: block;
      margin-bottom: 28px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &__ttl {
        width:100%;
        color: #33393C;
        font-weight: bold;
        font-size: 1.6rem;
      }

      &__required {
        margin-left: 8px;
        color:#E20000;
        font-size: 1.4rem;
      }

      &__data {
        width: 100%;
        flex: 1;
      }

      &__selectbox {
        width: 184px;
        margin-bottom: 12px;
      }

      &__txt {
        margin-left: 10px;
        font-size: 1.6rem;
        color: #33393C;
      }

      &__error {
        color: #E20000;
        font-size: 1.3rem;

        &:first-of-type {
          margin-top: 6px;
        }
      }

    }

    // ボタン
    &-btn {
      margin: 0 auto 16px;
      max-width: 240px;
    }

    // 送信完了画面
    &-end {
      &__lead {
        margin: 40px 0 0;
        font-size: 1.6rem;
        line-height: 2em;
        text-align: left;
      }

      &__wrap {
        padding: 24px 16px;
        margin: 48px 0 56px;
        border: 4px solid #D5D5D5;
        background-color: $whiteColor;
        text-align :center;
      }

      &__tel {
        margin-bottom: 16px;
        font-size: 2.4rem;
        font-weight: bold;
        color: #33393C;
      }

      &__address {
        font-size: 1.6rem;
        color: #33393C;
      }
    }
  }

  // 個人情報取扱同意書
  .agreement {
    &__link {
      margin-bottom: 32px;
      text-align: center;

      a {
        display: inline-block;
        text-decoration: underline;
      }
    }
  }

  // 同意する
  .agree {
    text-align: left;
    display: flex;
    justify-content: start;
    margin: 0 24px 50px 24px;

    &__txt {
      margin-left: 30px;
      font-size: 1.4rem;
      font-weight: normal;
      color: $whiteColor;
    }
  }

}