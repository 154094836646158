/* --------------------------------------------
  facebook_pc
----------------------------------------------- */

.facebook {
  width: 45%;
  max-width: 495px;
  padding: 0;
  margin: 0;

  &__contents {
    width: 100%;
    height: 100%;
  }
}