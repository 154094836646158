/* --------------------------------------------
  header_pc
----------------------------------------------- */

#top {
  .news {
    padding: 90px 0 80px;
    &__title {
      width: 212px;
      margin: 0 auto 39px;
    }

    &__inner {
      width: 90%;
      margin: 0 auto;
      max-width: $breakpoint-pc;
    }

    &__contents {
      margin-bottom: 48px;
    }

    .news-btn {
      width: 240px;
      margin: 0 auto;
    }
  }
}


@media only screen and (max-width: $breakpoint-pc) {
  #top {
    .news {
      &__title {
        width: 15%;
      }
    }
  }
}