/* --------------------------------------------
  footer_pc
----------------------------------------------- */

.footer {
  &__upper {
    background-color: #f7f7e6;

  }
  &__inner {
    position: relative;
    padding: 80px 0;
    max-width: $breakpoint-pc;
    margin: 0 auto;
  }

  &__backToTop {
    position: absolute;
    transform: translateY(-50%);
    top: 0;
    right: -8px;
    width: 80px;
    height: 80px;
    text-indent: -9999px;
    background-image: url("/assets/sweets/img/common/ico-pagetop.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &__image {
    width: 44%;
    max-width: 480px;
    margin: 0 auto 32px;
  }

  &__text {
    color: #633e28;
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.67;
  }

  &__lower {
    padding: 80px 0;
    text-align: center;

    small {
      font-size: 1.6rem;
      font-weight: 300;
    }
  }
}


@media only screen and (max-width: 1175px) {
  .footer {
    &__backToTop {
      transform: translate(-50%, -50%);
    }

  }
}


@media only screen and (max-width: $breakpoint-pc) {
  .footer {
    &__text {
      font-size: 1.4rem;
    }

    &__lower {
      small {
        font-size: 1.3rem;
      }
    }
  }
}
