@charset "UTF-8";

// reset
@import "./core/_reset";

// core
@import "./core/_font";
@import "./core/_variable";
@import "./core/_mixin";
@import "./core/_layout";

// libs
@import "libs/_slick.scss";


@media only screen and (max-width: $breakpoint - 1) {
  // components sp
  
@import "components/contact/_contact_sp.scss";
@import "components/footer/_footer_sp.scss";
@import "components/header/_header_sp.scss";
@import "components/sns/_facebook_sp.scss";
@import "components/sns/_instagram_sp.scss";
@import "components/sns/_sns_sp.scss";
  // module sp
  
@import "module/breadcrumb/_breadcrumb_sp.scss";
@import "module/btn-gold/_btn-gold_sp.scss";
@import "module/btn-tab/_tab-btn_sp.scss";
@import "module/btn-white-arrow/_btn-white-arrow_sp.scss";
@import "module/btn-white/_btn-white_sp.scss";
@import "module/form/_mod-form_sp.scss";
@import "module/heading/_heading_sp.scss";
@import "module/news-list/_news-list_sp.scss";
@import "module/pdf-list/_pdf-list_sp.scss";
  // page sp
  
@import "page/campaign/_campaign_sp.scss";
@import "page/concept/_concept_sp.scss";
@import "page/contact/_contact_sp.scss";
@import "page/contest/_contest_sp.scss";
@import "page/dogo-detail/_dogo-detail_sp.scss";
@import "page/dogo/_dogo_sp.scss";
@import "page/news/_detail_sp.scss";
@import "page/news/_news_sp.scss";
@import "page/top/_top_sp.scss";
}
@media only screen and (min-width: $breakpoint) {
  // components pc
  
@import "components/contact/_contact_pc.scss";
@import "components/footer/_footer_pc.scss";
@import "components/header/_header_pc.scss";
@import "components/sns/_facebook_pc.scss";
@import "components/sns/_instagram_pc.scss";
@import "components/sns/_sns_pc.scss";
  // module pc
  
@import "module/breadcrumb/_breadcrumb_pc.scss";
@import "module/btn-gold/_btn-gold_pc.scss";
@import "module/btn-tab/_tab-btn_pc.scss";
@import "module/btn-white-arrow/_btn-white-arrow_pc.scss";
@import "module/btn-white/_btn-white_pc.scss";
@import "module/form/_mod-form_pc.scss";
@import "module/heading/_heading_pc.scss";
@import "module/news-list/_news-list_pc.scss";
@import "module/pdf-list/_pdf-list_pc.scss";
  // page pc
  
@import "page/campaign/_campaign_pc.scss";
@import "page/concept/_concept_pc.scss";
@import "page/contact/_contact_pc.scss";
@import "page/contest/_contest_pc.scss";
@import "page/dogo-detail/_dogo-detail_pc.scss";
@import "page/dogo/_dogo_pc.scss";
@import "page/news/_detail_pc.scss";
@import "page/news/_news_pc.scss";
@import "page/top/_top_pc.scss";
}