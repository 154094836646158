/* --------------------------------------------
  pdf-list_pc
----------------------------------------------- */

.mod-pdf-list {
  width: 100%;

  .pdf-item {
    position: relative;
    padding: 31px 31px 31px 18px;
    border-bottom: 1px solid $goldColor;

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__image {
      width: 122px;
    }

    &__detail {
      flex: 1;
      padding-left: 32px;
    }

    &::before {
      position: absolute;
      content: "";
      vertical-align: middle;
      right: 18px;
      top: 50%;
      width: 5px;
      height: 5px;
      border-top: 2px solid $goldColor;
      border-right: 2px solid $goldColor;
      transform: rotate(45deg) translateY(-50%);
    }
  }

  .item-detail {

    &__title {
      margin-bottom: 3px;
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 1.5;
    }

    &__text {
      font-size: 1.6rem;
      line-height: 1.43;
      font-weight: 300;
    }

  }

}


@media only screen and (max-width: $breakpoint-pc) {
  .mod-pdf-list{

    .item-detail {

      &__title {
        font-size: 1.8rem;
      }

      &__text {
        font-size: 1.6rem;
      }

    }

  }
}

.mod-pdf-list.js-mod-pdf-list.is-hide {
  opacity: 0;
}