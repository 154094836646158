/* --------------------------------------------
  contact_sp
----------------------------------------------- */

.under-contact {

  &__wrap {
    width: 92%;
    margin: 0 auto;
    padding: 40px 10%;
    border: 1px solid $goldColor;
  }

  &__title {
    margin-bottom: 32px;
    font-size: 1.6rem;
  }

  &__address {
    dt {
      font-size: 1.6rem;
      font-weight: 500;

      span {
        font-weight: 300;
      }
    }

    dd {
      font-size: 1.6rem;
      font-weight: 300;
    }

    dd:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}
