/* --------------------------------------------
  contact_pc
----------------------------------------------- */

.under-contact {

  &__wrap {
    display: flex;
    max-width: $breakpoint-pc;
    width: 90%;
    margin: 0 auto;
    padding: 56px 32px;
    border: 1px solid $goldColor;
  }

  &__title {
    width: 156px;
    font-size: 1.6rem;
  }

  &__address {
    flex: 1;

    dt {
      font-size: 1.6rem;
      font-weight: 500;

      span {
        font-weight: 300;
      }
    }

    dd {
      font-size: 1.6rem;
      font-weight: 300;
    }

    dd:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  .only-pc {
    display: inline-block;
  }
}
