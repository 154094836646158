/* --------------------------------------------
  btn-white_pc
----------------------------------------------- */

.mod-btn-white-prev,
.mod-btn-white-next {
  position: relative;
  width: 100%;
  padding: 11px;
  border: 6px solid $goldColor;
  background-color: $whiteColor;
  border-radius: 100px;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  color: #633E28;
  line-height: 1.63;
  &::before {
    position: absolute;
    content: "";
    display: block;
    top: 50%;
    width: 7px;
    height: 7px;
    border-top: 2px solid $btnTextColor;
    border-right: 2px solid $btnTextColor;
    transform: rotate(45deg);
  }
}

.mod-btn-white-prev {
  &::before {
    left: 30px;
    transform: rotate(-135deg) translateY(50%);
  }
}

.mod-btn-white-next {
  &::before {
    right: 30px;
    transform: rotate(45deg) translateY(-50%);
  }
}