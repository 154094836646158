/* --------------------------------------------
  contest_pc
----------------------------------------------- */

#contest {
  .mod-heading {
    background-image: url("/assets/sweets/img/contest/bac-contest_pc.jpg");
  }

  .contents {
    padding: 0 0 120px;

    &__inner {
      margin: 0 auto 124px;
      max-width: $breakpoint-pc;
      width: 92%;
    }

    .top-btn {
      width: 240px;
      margin: 0 auto;
    }
  }
}