// --------------------------------------------------------------------------------
//    variable
// --------------------------------------------------------------------------------

// hover
$defOpacity: 0.5;

// font-family
$font_notoSans: 'Noto Sans JP', sans-serif;

// font-weight
$thin: 100;
$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;
$fontBlack: 900;

// breakpoint
$breakpoint: 768px;
$breakpoint-sp: 320px;
$breakpoint-pc: 1080px;

// duration
$duration: 0.5s;
$duration-fast: 0.25s;
$duration-slow: 1s;

// color
$textColor: #ffffff;
$primaryColor: #3B803B;
$whiteColor: #FFFFFF;
$blackColor: #151515;
$grayColor: #848484;
$beigeColor: #F7F5EF;
$orangeColor: #F09400;
$goldColor: #D3D27B;
$blueColor: #0B73DB;
$btnTextColor: #4A4A4A;