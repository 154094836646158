/* --------------------------------------------
  instagram_pc
----------------------------------------------- */
.instagram {
  width: 51%;
  max-width: 550px;

  &__inner {
    width: 100%;
  }

  &__contents {
    width: 100%;
    margin-bottom: 34px;
  }

  &__list {
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__item {
    width: 31%;
    margin-bottom: 16px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 175px;
    margin: 0 auto;
    padding: 10px;
    font-size: 1.6rem;
    background-color: $goldColor;
    color: $btnTextColor;
    border-radius: 100px;
    &::before {
      content: '';
      display: inline-block;
      margin-right: 8px;
      width: 23px;
      height: 23px;
      background-image: url("/assets/sweets/img/common/ico-instagram.svg");
    }
  }

}

// @media only screen and (min-width: $breakpoint - 1) and (max-width: 1100px) {
//   .instagram {
//     display: block;
//     margin: auto;
//   }
// }