/* --------------------------------------------
  detail_pc
----------------------------------------------- */

#detail{

  .mod-heading {
    background-image: url("/assets/sweets/img/news/bac-news_pc.jpg");
  }

  .article {
    padding-bottom: 40px;
    width: 90%;
    max-width: $breakpoint-pc;
    margin: 0 auto;

    &__heading{
      display: flex;
      width: 100%;
      margin-bottom: 12px;
    }

    &__day {
      font-size: 1.4rem;
      margin-right: 16px;
    }

    &__tag {
      padding: 2px 14px;
      background-color: $goldColor;
      color: $btnTextColor;
      font-size: 1.0rem;
      line-height: 1.5;
      border-radius: 100px;
    }

    &__inner {
      width: 98%;
      margin: 0 auto;
    }

    &__title {
      margin-bottom: 48px;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 1.5;
    }

    &__text {

      h3 {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.5;
      }

      h4 {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1.5;
      }

      p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2;
      }

      a {
        display: inline-block;
        word-break: break-all;
        position: relative;
        padding: 9px 15px 9px 0;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.29;
        border-bottom: solid 2px #fff;

        &::before {
          content: '';
          position: absolute;
          top: 56%;
          right: 0;
          transform: translate(0, -50%);
          display: block;
          width: 7px;
          height: 11px;
          background-image: url(/assets/sweets/img/news/ico-arrow-white.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }

      ul {
        margin-left: 5px;
        li {
          position: relative;
          margin: 0 0 -1px 0;
          padding: 0 0 0 21px;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 2;

          &::before {
            content: '';
            position: absolute;
            top: calc(1.6rem * 2 / 2);
            left: 5px;
            transform: translate(0, -50%);
            display: block;
            width: 8px;
            height: 8px;
            background: $goldColor;
            border-radius: 100%;
          }

          &:last-child {
            margin: 0;
          }
        }
      }

      ol {
        margin-left: 5px;
        counter-reset: articleDetail;

        li {
          position: relative;
          margin: 0 0 7px 0;
          padding: 0 0 0 26px;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 2;

          &::before {
            counter-increment: articleDetail;
            content: counter(articleDetail);
            position: absolute;
            top: calc(1.6rem * 2 / 2);
            left: 0;
            transform: translate(0, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 18px;
            height: 18px;
            font-size: 1.1rem;
            font-weight: 700;
            line-height: 0;
            color: #151515;
            background: $goldColor;
            border-radius: 100%;
          }

          &:last-child {
            margin: 0;
          }
        }
      }

      table {
        margin-left: 10px;
        overflow-x: auto;
        display: block;

        td {
          padding: 7px 19px;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.57;
          white-space: nowrap;
          color: #4A4A4A;
          background: #FFFFFF;
          border: solid 1px $goldColor;
          vertical-align: middle;
        }
      }

      .file {
        margin: 30px auto 30px;
        a {
          width: 90%;
          max-width: 318px;
          margin: 0 auto;
        }
        p {
          font-size: 1.6rem;
          text-align: center;
          font-weight: 300;
        }
      }
      .image {
        width: 90%;
        max-width: 318px;
        margin: 30px auto;
      }
    }
  }

  .page-btn {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 536px;
    & > a {
      width: 240px;
      margin-bottom: 40px;
      &:not(:first-child) {
        margin-left: 50px;
      }
    }
  }

  .page-top-btn {
    width: 240px;
    margin: 0 auto 120px;
  }
}