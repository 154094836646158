/* --------------------------------------------
  header_sp
----------------------------------------------- */

.header {
  height: 100px;
  background: linear-gradient(#000 0%, #4a4a4a 100%);

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 7%;
    height: 100%;
  }
  &__logo {
    max-width: 278px;
    width: 85%;
  }

  .nav {
    &__button {
      width: 28px;
      height: 22px;

      button {
        position: relative;
        width: 100%;
        height: 100%;

        &::before,
        &::after,
        span {
          content: '';
          position: absolute;
          left: 0;
          display: block;
          width: 100%;
          height: 3px;
          text-indent: -9999px;
          background: $whiteColor;
          transition: all 0.5s ease-out 0s;
        }

        &::before {
          top: 0;
          transform: translate(0, 0) rotate(0);
        }

        &::after {
          top: 100%;
          transform: translate(0, -100%) rotate(0);
        }

        span {
          top: 50%;
          transform: translate(0, -50%) rotate(0);
        }

        &:hover {
          opacity: 1;
        }
      }

      &.is-open {
        button {
          &::before,
          &::after {
            top: 50%;
            left: 50%;
            width: 114.53%;
          }

          &::before {
            transform: translate(-50%, -50%) rotate(30deg);
          }

          &::after {
            transform: translate(-50%, -50%) rotate(-30deg);
          }

          span {
            width: 0;
          }
        }
      }
    }

    &__content {
      display: none;
      position: fixed;
      top: 100px;
      left: 0;
      width: 100%;
      height: calc(100% - 56px);
      background-color: $blackColor;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-scrolling: touch;
      z-index: 10;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__menu {
      width: 90%;
      margin: 40px auto;
      li {
        margin-bottom: 32px;
        &, & > a {
          text-align: center;
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 1.44;
          color: $whiteColor;
          span {
            display: block;
            margin-top: 8px;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.42;
            color: $grayColor;
          }
        }

        &.nav__disabled {
          color: $grayColor;
        }
      }
    }
  }
}