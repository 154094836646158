/* --------------------------------------------
  heading_sp
----------------------------------------------- */

// background-imageを指定する想定です

.mod-heading {
  position: relative;
  padding-top: 67%;
  background-size: cover;
  background-position: bottom left;
  background-repeat: no-repeat;

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    font-size: 2.0em;
    font-weight: 500;
  }
}


@media only screen and (max-width: $breakpoint-sp) {
  .mod-heading {
    &__title {
      font-size: 1.8em;
    }
  }
}