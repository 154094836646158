// --------------------------------------------------------------------------------
//    Mixin items
// --------------------------------------------------------------------------------

// cleafix
@mixin clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

// font（font-size, line-height, color, letterspacing, font-weight）
@mixin font(
  $fontsize,
  $lineheight,
  $color,
  $letterspacing: normal,
  $fontweight: normal
) {
  font-size: $fontsize + px;
  font-size: ($fontsize / 10) + rem;
  @if $lineheight == normal {
    line-height: normal;
  } @else {
    line-height: $lineheight;
  }
  @if $color != "none" {
    color: $color;
  }
  letter-spacing: $letterspacing;
  font-weight: $fontweight;
}

// backgroundImage（imagePath）
@mixin backgroundImage($path) {
  background-image: url($path);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  @content;
}

// 縦真ん中寄せ
@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

// absolute horizontal and vertival center
@mixin abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}