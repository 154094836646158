/* --------------------------------------------
  btn-white_pc
----------------------------------------------- */

.mod-btn-white {
  width: 100%;
  padding: 11px;
  border: 6px solid $goldColor;
  background-color: $whiteColor;
  border-radius: 100px;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  color: #633E28;
  line-height: 1.63;

  &:disabled {
    cursor: initial;
    color: #9B9B9B;
    background: #F4F4F4;
    border: solid 6px #C6C6C6;
    &:hover {
      opacity: 1;
    }
  }
}