/* --------------------------------------------
  header_pc
----------------------------------------------- */

.header {
  height: auto;
  padding-top: 29px;
  background: linear-gradient(#000 0%, #4a4a4a 100%);

  &__inner {
    max-width: $breakpoint-pc;
    width: 95%;
    margin: 0 auto;
  }

  &__logo {
    width: 378px;
    height: auto;
    margin: 0 auto 23px;
    text-align: center;
  }

  .nav {
    border-top: 1px solid #707070;
    &__content {
      display: block;
    }
    &__menu {
      display: flex;
      justify-content: space-between;
      width: 91%;
      margin: 0 auto;
      padding: 22px 0 15px;

      li {
        &, & > a {
          text-align: center;
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 1.46;
          color: $whiteColor;
          span {
            display: block;
            margin-top: 8px;
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 1.5;
            color: $grayColor;
          }
        }

        &.nav__disabled {
          color: $grayColor;
        }
      }
    }
  }
}


@media only screen and (max-width: $breakpoint-pc) {
  .header {
    .nav {
      li {
        &, & > a {
          font-size: 1.3rem;
          span {
            font-size: 1.0rem;
          }
        }
      }
    }
  }
}